<template>
  <ax-form-builder
    :formBuilder="formBuilder"
    @change="changeValue1"
    ref="formBuilder"
  >
    <template slot="deptId">
      <a-tree-select
        v-model="selectTreeValue"
        style="width: 100%"
        :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
        :tree-data="treeData"
        placeholder="Please select"
        tree-default-expand-all
        :disabled="true"
        @change="treeSelectChange"
      >
        <span
          v-if="key === '0-0-1'"
          slot="title"
          slot-scope="{ key, value }"
          style="color: #08c"
        >
          Child Node1 {{ value }}
        </span>
      </a-tree-select>
    </template>
    <template slot="file">
      <file-upload
        ref="file"
        class="file"
        fileType="SYS_STAFF"
        :reportId="this.$route.query.reportId"
        :taskDefinitionKey="this.$route.query.taskDefinitionKey===undefined?'PROCESS_SUBMIT':this.$route.query.taskDefinitionKey"
      >
      </file-upload>
    </template>

  </ax-form-builder>
</template>

<script>
import { setAttribute, isEmpty } from '@/common/tools'
import moment from 'moment'
import api from './api'
import fileUpload from '@/components/file-upload/index.vue'
export default {
  name: 'maintain',
  props: ['currentTable', 'deptId'],
  inject: ['changeValue', 'getList'],
  components: {
    fileUpload
  },
  data () {
    return {
      deptData: [],
      selectTreeValue: '',
      selectTreeLable: '',
      treeData: [],
      watchShow: false,
      formBuilder: {
        formConfig: {
          labelWidth: 100,
          hideRequiredMark: false,
          labelAlign: 'right',
          layout: 'horizontal',
          colon: true,
          modalWidth: 1000
        },
        formValues: {
          inputNumber_ef63210466da666bb6818e698ae6aa2: 4
        },
        childrenNodes: [
          {
            type: 'axNativeTable',
            isLeaf: false,
            icon: 'icon-kapian',
            key: 'axNativeTable_bed1fef33d31e30f43874d3f087b979',
            label: '表格布局',
            model: 'axNativeTable_bed1fef33d31e30f43874d3f087b979',
            options: {
              small: false,
              bordered: true,
              bright: false,
              width: '100%'
            },
            childrenNodes: [
              {
                type: 'tr',
                isLeaf: false,
                options: {},
                childrenNodes: [
                  {
                    type: 'axTd',
                    isLeaf: false,
                    renderAsSlot: true,
                    options: {
                      colspan: 1,
                      rowspan: 1
                    },
                    childrenNodes: [
                      {
                        type: 'input',
                        isLeaf: true,
                        icon: 'icon-shurukuang',
                        key: 'input_d948a935ea5c5e810e8ed8e34faf8fe',
                        label: '姓名',
                        model: 'userName',
                        options: {
                          disabled: false,
                          size: 'default',
                          allowClear: true,
                          width: '100%',
                          placeholder: '请输入'
                        },
                        formItem: {},
                        rules: [
                          {
                            required: true,
                            message: '不能为空',
                            dynamicKey: 'required'
                          }
                        ]
                      },
                      {
                        type: 'hidden',
                        isLeaf: true,
                        icon: 'icon-shurukuang',
                        key: 'input_d948a935ea5c5e810e8ed8e34faf8fe',
                        label: '',
                        model: 'id',
                        options: {
                          disabled: false,
                          size: 'default',
                          hidden: true,
                          allowClear: true,
                          width: '1%'
                        },
                        formItem: {},
                        rules: []
                      }
                    ]
                  },
                  {
                    type: 'axTd',
                    isLeaf: false,
                    renderAsSlot: true,
                    options: {
                      colspan: 1,
                      rowspan: 1
                    },
                    childrenNodes: [
                      {
                        type: 'select',
                        isLeaf: true,
                        icon: 'icon-xialaxuanze',
                        key: 'select_5af95cad4dd4d467e49f314bfa9c47a',
                        label: '性别',
                        model: 'sex',
                        options: {
                          allowClear: true,
                          autoClearSearchValue: true,
                          disabled: false,
                          dropdownMatchSelectWidth: true,
                          labelInValue: false,
                          mode: 'single',
                          placeholder: '请输入',
                          showSearch: false,
                          showArrow: true,
                          size: 'default',
                          dataSourceType: 'staticData',
                          dataSource: [
                            {
                              label: '男',
                              value: '1',
                              disabled: false
                            },
                            {
                              label: '女',
                              value: '0',
                              disabled: false
                            }
                          ],
                          dicData: [],
                          width: '100%'
                        },
                        formItem: {},
                        rules: [
                          {
                            required: true,
                            message: '不能为空',
                            dynamicKey: 'required'
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                type: 'tr',
                isLeaf: false,
                options: {},
                childrenNodes: [
                  {
                    type: 'axTd',
                    isLeaf: false,
                    renderAsSlot: true,
                    options: {
                      colspan: 1,
                      rowspan: 1
                    },
                    childrenNodes: [
                      {
                        type: 'datePicker',
                        isLeaf: true,
                        icon: 'icon-riqixuanze',
                        key: 'datePicker_537ee27a77914791178ff37506399fb',
                        label: '生日',
                        model: 'birthday',
                        options: {
                          allowClear: true,
                          disabled: false,
                          placeholder: '请选择',
                          size: 'default',
                          inputReadOnly: false,
                          format: 'YYYY-MM-DD',
                          showTime: false,
                          showToday: true,
                          width: '100%',
                          disabledDate: this.disableDate
                        },
                        formItem: {},
                        rules: [
                          {
                            required: false,
                            message: '不能为空',
                            dynamicKey: 'required'
                          }
                        ]
                      }
                    ]
                  },
                  {
                    type: 'axTd',
                    isLeaf: false,
                    renderAsSlot: true,
                    options: {
                      colspan: 1,
                      rowspan: 1
                    },
                    childrenNodes: [
                      {
                        type: 'input',
                        isLeaf: true,
                        icon: 'icon-shurukuang',
                        key: 'input_c5b6181dddbd4d8c4da2dcd08404894',
                        label: '身份证',
                        model: 'identityCard',
                        options: {
                          disabled: false,
                          size: 'default',
                          allowClear: true,
                          width: '100%',
                          placeholder: '请输入',
                          maxLength:18
                        },
                        formItem: {},
                        rules: [
                          {
                            required: false,
                            message: '不能为空'
                          }
                          // ,{
                          //   validator: this.checkIdCard
                          // }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                type: 'tr',
                isLeaf: false,
                options: {},
                childrenNodes: [
                  {
                    type: 'axTd',
                    isLeaf: false,
                    renderAsSlot: true,
                    options: {
                      colspan: 1,
                      rowspan: 1
                    },
                    childrenNodes: [
                      {
                        type: 'inputNumber',
                        isLeaf: true,
                        icon: 'icon-shurukuang',
                        key: 'input_d3d6c13da4f24a2d5a8eb6a03dde2e6',
                        label: '年龄',
                        model: 'age',
                        options: {
                          disabled: false,
                          size: 'default',
                          allowClear: true,
                          max: 120,
                          width: '100%',
                          placeholder: '根据生日自动计算'
                        },
                        formItem: {},
                        rules: [
                          {
                            required: false,
                            message: '不能为空'
                          }
                        ]
                      }
                    ]
                  },
                  {
                    type: 'axTd',
                    isLeaf: false,
                    renderAsSlot: true,
                    options: {
                      colspan: 1,
                      rowspan: 1
                    },
                    childrenNodes: [
                      {
                        type: 'input',
                        isLeaf: true,
                        icon: 'icon-shurukuang',
                        key: 'input_4349f432f5f3ff74af8550fdeebfa0e',
                        label: '电子邮件',
                        model: 'email',
                        options: {
                          disabled: false,
                          size: 'default',
                          allowClear: true,
                          width: '100%',
                          placeholder: '请输入'
                        },
                        formItem: {},
                        rules: [
                          {
                            required: false,
                            message: '不能为空',
                            dynamicKey: 'required'
                          },
                          {
                            validator: this.checkEmail
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                type: 'tr',
                isLeaf: false,
                options: {},
                childrenNodes: [
                  {
                    type: 'axTd',
                    isLeaf: false,
                    renderAsSlot: true,
                    options: {
                      colspan: 1,
                      rowspan: 1
                    },
                    childrenNodes: [
                      {
                        type: 'inputNumber',
                        isLeaf: true,
                        icon: 'icon-shuzishuru',
                        key: 'inputNumber_07ae5f366955f600268e50685b213ca01',
                        label: '电话',
                        model: 'phone',
                        options: {
                          disabled: false,
                          decimalSeparator: '.',
                          size: 'default',
                          step: 1,
                          width: '100%',
                          placeholder: '请输入'
                        },
                        formItem: {},
                        rules: [
                          {
                            required: true,
                            message: '不能为空'
                          }

                          // {
                          //   pattern: new RegExp(/^1[3456789]\d{9}$/, 'g')
                          // }
                        ]
                      }
                    ]
                  },
                  {
                    type: 'axTd',
                    isLeaf: false,
                    renderAsSlot: true,
                    options: {
                      colspan: 1,
                      rowspan: 1
                    },
                    childrenNodes: [
                      {
                        type: 'inputNumber',
                        isLeaf: true,
                        icon: 'icon-shuzishuru',
                        key: 'inputNumber_07ae5f366955f600268e50685b27701',
                        label: '短号',
                        model: 'cornet',
                        options: {
                          disabled: false,
                          decimalSeparator: '.',
                          size: 'default',
                          step: 1,
                          width: '100%',
                          placeholder: '请输入'
                        },
                        formItem: {},
                        rules: [
                          {
                            required: false,
                            message: '不能为空'
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                type: 'tr',
                isLeaf: false,
                options: {},
                childrenNodes: [
                  {
                    type: 'axTd',
                    isLeaf: false,
                    renderAsSlot: true,
                    options: {
                      colspan: 1,
                      rowspan: 1
                    },
                    childrenNodes: [
                      {
                        type: 'inputNumber',
                        isLeaf: true,
                        icon: 'icon-shuzishuru',
                        key: 'inputNumber_07ae5f366955f600268e50685b27701',
                        label: '座机号',
                        model: 'landlineNo',
                        options: {
                          disabled: false,
                          decimalSeparator: '.',
                          size: 'default',
                          step: 1,
                          width: '100%',
                          placeholder: '请输入'
                        },
                        formItem: {},
                        rules: [
                          {
                            required: false,
                            message: '不能为空'
                          }
                        ]
                      }
                    ]
                  },
                  {
                    type: 'axTd',
                    isLeaf: false,
                    renderAsSlot: true,
                    options: {
                      colspan: 1,
                      rowspan: 1
                    },
                    childrenNodes: [
                      {
                        type: 'inputNumber',
                        isLeaf: true,
                        icon: 'icon-shuzishuru',
                        key: 'inputNumber_07ae5f366955f600268e50685b27701',
                        label: '科室号',
                        model: 'deptNo',
                        options: {
                          disabled: false,
                          decimalSeparator: '.',
                          size: 'default',
                          step: 1,
                          width: '100%',
                          placeholder: '请输入'
                        },
                        formItem: {},
                        rules: [
                          {
                            required: false,
                            message: '不能为空'
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                type: 'tr',
                isLeaf: false,
                options: {},
                childrenNodes: [
                  {
                    type: 'axTd',
                    isLeaf: false,
                    renderAsSlot: true,
                    options: {
                      colspan: 1,
                      rowspan: 1
                    },
                    childrenNodes: [
                      {
                        type: 'input',
                        isLeaf: true,
                        icon: 'icon-shurukuang',
                        key: 'input_d3d6c13da4f24a2d5a8eb6a03ddece6',
                        label: '专业',
                        model: 'major',
                        options: {
                          disabled: false,
                          size: 'default',
                          allowClear: true,
                          width: '100%',
                          placeholder: '请输入'
                        },
                        formItem: {},
                        rules: [
                          {
                            required: false,
                            message: '不能为空',
                            dynamicKey: 'required'
                          }
                        ]
                      }
                    ]
                  },
                  {
                    type: 'axTd',
                    isLeaf: false,
                    renderAsSlot: true,
                    options: {
                      colspan: 1,
                      rowspan: 1
                    },
                    childrenNodes: [
                      {
                        type: 'select',
                        isLeaf: true,
                        icon: 'icon-xialaxuanze',
                        key: 'select_ba07d3361905b165e18d001222d7033',
                        label: '聘用方式',
                        model: 'engageWay',
                        options: {
                          allowClear: true,
                          autoClearSearchValue: true,
                          disabled: false,
                          dropdownMatchSelectWidth: true,
                          labelInValue: false,
                          mode: 'single',
                          placeholder: '请输入',
                          showSearch: false,
                          showArrow: true,
                          size: 'default',
                          dataSourceType: 'staticData',
                          dataSource: [
                          ],
                          dicData: [],
                          width: '100%'
                        },
                        formItem: {},
                        rules: [
                          {
                            required: false,
                            message: '不能为空',
                            dynamicKey: 'required'
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                type: 'tr',
                isLeaf: false,
                options: {},
                childrenNodes: [
                  {
                    type: 'axTd',
                    isLeaf: false,
                    renderAsSlot: true,
                    options: {
                      colspan: 1,
                      rowspan: 1
                    },
                    childrenNodes: [
                      {
                        type: 'select',
                        isLeaf: true,
                        icon: 'icon-shurukuang',
                        key: 'input_d14e7da6ad55eafa8a8768af7f429aa',
                        label: '编制',
                        model: 'structural',
                        options: {
                          disabled: false,
                          size: 'default',
                          allowClear: true,
                          width: '100%',
                          placeholder: '请输入',
                          dataSource: [
                            { label: '有', value: '有' },
                            { label: '无', value: '无' }
                          ]
                        },
                        formItem: {},
                        rules: [
                          {
                            required: false,
                            message: '不能为空',
                            dynamicKey: 'required'
                          }
                        ]
                      }
                    ]
                  },
                  {
                    type: 'axTd',
                    isLeaf: false,
                    renderAsSlot: true,
                    options: {
                      colspan: 1,
                      rowspan: 1
                    },
                    childrenNodes: [
                      {
                        type: 'input',
                        isLeaf: true,
                        icon: 'icon-xialaxuanze',
                        key: 'select_240b4cffe5468ebbce9975ea93aa663',
                        label: '职称/级别',
                        model: 'professional',
                        options: {
                          allowClear: true,
                          autoClearSearchValue: true,
                          disabled: false,
                          dropdownMatchSelectWidth: true,
                          labelInValue: false,
                          mode: 'single',
                          placeholder: '请输入',
                          showSearch: false,
                          showArrow: true,
                          size: 'default',
                          dataSourceType: 'staticData',
                          dataSource: [
                          ],
                          dicData: [],
                          width: '100%'
                        },
                        formItem: {},
                        rules: [
                          {
                            required: false,
                            message: '不能为空',
                            dynamicKey: 'required'
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                type: 'tr',
                isLeaf: false,
                options: {},
                childrenNodes: [
                  {
                    type: 'axTd',
                    isLeaf: false,
                    renderAsSlot: true,
                    options: {
                      colspan: 1,
                      rowspan: 1
                    },
                    childrenNodes: [
                      {
                        type: 'axTreeSelect',
                        isLeaf: true,
                        icon: 'icon-xialaxuanze',
                        key: 'select_0122d6e3e786ae0b8e8c07ed3a87739',
                        label: '部门',
                        model: 'deptId',
                        options: {
                          allowClear: true,
                          autoClearSearchValue: true,
                          disabled: false,
                          dropdownMatchSelectWidth: true,
                          labelInValue: false,
                          mode: 'single',
                          placeholder: '请输入',
                          showSearch: false,
                          showArrow: true,
                          size: 'default',
                          // dataSourceType: 'staticData',
                          dataSource: [
                          ],
                          dicData: [],
                          width: '100%'
                        },
                        formItem: {},
                        rules: [
                          {
                            required: true,
                            message: '不能为空',
                            dynamicKey: 'required'
                          }
                        ]
                      }
                    ]
                  },
                  {
                    type: 'axTd',
                    isLeaf: false,
                    renderAsSlot: true,
                    options: {
                      colspan: 1,
                      rowspan: 1
                    },
                    childrenNodes: [
                      {
                        type: 'input',
                        isLeaf: true,
                        icon: 'icon-shurukuang',
                        key: 'input_a50fb4c089368845289eb58c24ecc52',
                        label: '职务',
                        model: 'duty',
                        options: {
                          disabled: false,
                          size: 'default',
                          allowClear: true,
                          width: '100%',
                          placeholder: '请输入'
                        },
                        formItem: {},
                        rules: [
                          {
                            required: false,
                            message: '不能为空',
                            dynamicKey: 'required'
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                type: 'tr',
                isLeaf: false,
                options: {},
                childrenNodes: [
                  {
                    type: 'axTd',
                    isLeaf: false,
                    renderAsSlot: true,
                    options: {
                      colspan: 1,
                      rowspan: 1
                    },
                    childrenNodes: [
                      {
                        type: 'select',
                        isLeaf: true,
                        icon: 'icon-xialaxuanze',
                        key: 'select_0122d6e3e786ae0b8e8c07ed3a87739',
                        label: '学历',
                        model: 'education',
                        options: {
                          allowClear: true,
                          autoClearSearchValue: true,
                          disabled: false,
                          dropdownMatchSelectWidth: true,
                          labelInValue: false,
                          mode: 'single',
                          placeholder: '请输入',
                          showSearch: false,
                          showArrow: true,
                          size: 'default',
                          // dataSourceType: 'staticData',
                          dataSource: [
                          ],
                          dicData: [],
                          width: '100%'
                        },
                        formItem: {},
                        rules: [
                          {
                            required: false,
                            message: '不能为空',
                            dynamicKey: 'required'
                          }
                        ]
                      }
                    ]
                  },
                  {
                    type: 'axTd',
                    isLeaf: false,
                    renderAsSlot: true,
                    options: {
                      colspan: 1,
                      rowspan: 1
                    },
                    childrenNodes: [
                      {
                        type: 'input',
                        isLeaf: true,
                        icon: 'icon-shurukuang',
                        key: 'input_a50fb4c089368845289eb58c24ecc52',
                        label: '学位',
                        model: 'degree',
                        options: {
                          allowClear: true,
                          autoClearSearchValue: true,
                          disabled: false,
                          dropdownMatchSelectWidth: true,
                          labelInValue: false,
                          mode: 'single',
                          placeholder: '请输入',
                          showSearch: false,
                          showArrow: true,
                          size: 'default',
                          // dataSourceType: 'staticData',
                          dataSource: [
                          ],
                          dicData: [],
                          width: '100%'
                        },
                        formItem: {},
                        rules: [
                          {
                            required: false,
                            message: '不能为空',
                            dynamicKey: 'required'
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                type: 'tr',
                isLeaf: false,
                options: {},
                childrenNodes: [

                  {
                    type: 'axTd',
                    isLeaf: false,
                    renderAsSlot: true,
                    options: {
                      colspan: 1,
                      rowspan: 1
                    },
                    childrenNodes: [
                      {
                        type: 'datePicker',
                        isLeaf: true,
                        icon: 'icon-shurukuang',
                        key: 'input_a50fb4c089368845289eb58c24ecc52',
                        label: '首次工作时间',
                        model: 'workDate',
                        options: {
                          disabled: false,
                          size: 'default',
                          allowClear: true,
                          width: '100%',
                          placeholder: '请输入'
                        },
                        formItem: {},
                        rules: [
                          {
                            required: false,
                            message: '不能为空',
                            dynamicKey: 'required'
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                type: 'tr',
                isLeaf: false,
                options: {},
                childrenNodes: [
                  {
                    type: 'axTd',
                    isLeaf: false,
                    renderAsSlot: true,
                    options: {
                      colspan: 2,
                      rowspan: 1
                    },
                    childrenNodes: [
                      {
                        type: 'input',
                        isLeaf: true,
                        icon: 'icon-xialaxuanze',
                        key: 'select_0122d6e3e786ae0b8e8c07ed3a87739',
                        label: '',
                        model: 'file',
                        options: {
                          allowClear: true,
                          autoClearSearchValue: true,
                          disabled: false,
                          dropdownMatchSelectWidth: true,
                          labelInValue: false,
                          mode: 'single',
                          placeholder: '请输入',
                          showSearch: false,
                          showArrow: true,
                          size: 'default',
                          // dataSourceType: 'staticData',
                          dataSource: [
                          ],
                          dicData: [],
                          width: '100%'
                        },
                        formItem: {},
                        rules: [
                          {
                            required: false,
                            message: '不能为空',
                            dynamicKey: 'required'
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    }
  },
  watch: {
    currentTable: {
      handler (val) {
        console.log(val)
        if (this.watchShow) {
          this.getData(val.id)
        }
      },
      deep: true,
      immediate: true
    }
  },
  beforeDestroy () {
    this.selectTreeValue = ''
    this.selectTreeLable = ''
  },
  mounted () {
    this.getData()
    this.watchShow = true
    // this.deptList()
    // 聘用
    this.engageWay()
    // 职称
    this.professional()
    // 学位
    this.degree()
    // 学历
    this.education()
    this.getCopyList()
  },

  methods: {
    getCopyList () {
      api.queryExternalTree().then(res => {
        this.treeData = res.data
      })
    },
    // 身份证验证
    checkIdCard (rule, value, callback) {
      if (value) {
        if (!/^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(value)) {
          callback(new Error('请输入正确的身份证号码'))
        } else {
          callback()
        }
      } else {
        callback()
      }
    },
    // 邮箱验证
    checkEmail (rule, value, callback) {
      if (value) {
        if (!/^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/.test(value)) {
          callback(new Error('请输入正确的邮箱'))
        } else {
          callback()
        }
      } else {
        callback()
      }
    },
    // 手机号验证---注释原因：验证失败
    // checkPhone (rule, value, callback) {
    //   if (value) {
    //     if (!/^1[3456789]\d{9}$/.test(value)) {
    //       callback(new Error('请输入正确的手机号'))
    //     } else {
    //       callback()
    //     }
    //   } else {
    //     callback()
    //   }
    // },
    getData (val) {
      this.$refs.formBuilder.setFieldsValue()
    },
    disableDate (current) {
      return current > moment().add(-1, 'day')
    },
    submit () {
      this.$refs.formBuilder.form.validateFields(async (errors, values) => {
        if (!errors) {
          const report = values
          const fileIds = []
          this.$refs.file.data.forEach(res => {
            fileIds.push(res.id)
          })
          report.fileIds = fileIds
          report.deptId = this.selectTreeValue
          report.deptName = this.selectTreeLable

          // 验证手机号是否输入正确
          if (!/^1[3456789]\d{9}$/.test(values.phone)) {
            // callback(new Error('请输入正确的手机号'))
            this.$message.error('请输入正确的手机号')
            return false
          }
          // 验证身份证号是否正确
          if (!isEmpty(values.identityCard)) {
            if (!/^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(values.identityCard)) {
              this.$message.error('请输入正确的身份证号码')
              return false
            }
          }

          api.save(report).then(res => {
            this.$message.success('操作成功')
            this.getList()
            this.changeValue('addShow', false)
            const obj = {}
            Object.keys(this.$refs.formBuilder.getFieldsValue()).forEach(res => {
              obj[res] = ''
            })
            this.$refs.formBuilder.setFieldsValue(obj)
          })
        } else {
          this.$message.warning('请填写完整表单信息')
        }
      })
    },
    async professional () {
      await api.dictData({ dicKind: 'staff_professional' }).then(res => {
        const options = res.data.map(res => { return { label: res.dicDesc, value: res.dicCode } })
        setAttribute(this.$refs.formBuilder.formBuilder.childrenNodes, 'professional', 'dataSource', options)
      })
    },
    async degree () {
      await api.dictData({ dicKind: 'staff_degree' }).then(res => {
        const options = res.data.map(res => { return { label: res.dicDesc, value: res.dicCode } })
        setAttribute(this.$refs.formBuilder.formBuilder.childrenNodes, 'degree', 'dataSource', options)
      })
    },
    async education () {
      await api.dictData({ dicKind: 'staff_education' }).then(res => {
        const options = res.data.map(res => { return { label: res.dicDesc, value: res.dicCode } })
        setAttribute(this.$refs.formBuilder.formBuilder.childrenNodes, 'education', 'dataSource', options)
      })
    },
    async engageWay () {
      await api.dictData({ dicKind: 'staff_engage_way' }).then(res => {
        const options = res.data.map(res => { return { label: res.dicDesc, value: res.dicCode } })
        setAttribute(this.$refs.formBuilder.formBuilder.childrenNodes, 'engageWay', 'dataSource', options)
      })
    },
    // async deptList () {
    //   await api.treeList().then(res => {
    //     this.deptData = res.data.map(res => { return { label: res.deptName, value: res.id } })
    //     // setAttribute(this.$refs.formBuilder.formBuilder.childrenNodes, 'deptId', 'treeData', this.deptData)
    //   })
    // },
    treeSelectChange (value, label, extra) {
      this.selectTreeValue = value
      this.selectTreeLable = label
    },
    setSelectTreeValue (obj) {
      this.selectTreeValue = obj.deptId
      this.selectTreeLable = obj.deptName
      console.log(this.selectTreeValue, 'this.selectTreeValue')
    },
    changeValue1 (value, key) {
      switch (key) {
        case 'birthday':
          this.ageCalculation(value)
          break
      }
    },

    ageCalculation (value) {
      const birthdays = new Date(value.replace(/-/g, '/'))
      console.log('生日转换时间', birthdays)
      const d = new Date()
      const age = d.getFullYear() - birthdays.getFullYear() - (d.getMonth() < birthdays.getMonth() || (d.getMonth() === birthdays.getMonth() && d.getDate() < birthdays.getDate()) ? 1 : 0)
      console.log('年龄', age)
      this.$refs.formBuilder.setFieldsValue({ age: age })
      return age
    }
  }
}
</script>
